// NOTE: privacy policy and tos may contain a string $langcode which will be replaced with the currently set language code of the user
export const termsAndConditionsLink =
  "https://www.incontext.technology/impressum.html";
export const privacyPolicyLink =
  "https://www.incontext.technology/datenschutz.html";

export const headerlogo = "/img/logo.png";
export const loginImage = "/img/login-image.svg";

export const showDetailsForQuiz = false;
export const showDetailsForSurvey = false;
export const showSeal = false;
export const showCovidView = false;
export const showCovidCertificatesToAdmin = true;
export const showTestCenterView = false;
export const showHardwareRestrictions = true;
export const userStateShowDownload = true;

export const title = "lilli e-learning";

export const registrationRequiresKey = true;
// possible values: phone, address, birthday, company, lastname, firstname, birthyear
export const registrationRequiredDetails = {
  phone: { request: false, mandatory: false },
  address: { request: false, mandatory: false },
  firstname: { request: true, mandatory: true },
  lastname: { request: true, mandatory: true },
  birthday: { request: true, mandatory: true },
  company: { request: false, mandatory: false },
  emergencyContact: { request: true, mandatory: false },
};

export const customPublicRegistrations = {};

export const qGateFields = [
  { id: "birthdate", type: "date", label: "birthday", mandatory: true },
  // {id: 'birthplace', type: 'string', label: 'birthplace', mandatory: true},
  // NOTE: nationalityWithNonEuVisa also adds the additional, optional fields socialSecurity and workPermit (if the user is not from the EU)
  {
    id: "nationality",
    type: "nationalityWithNonEuVisa",
    label: "nationality",
    mandatory: true,
  },
  // {id: 'address', type: 'string', label: 'address', mandatory: true},
  // {id: 'city', type: 'string', label: 'city', mandatory: true},
  // {id: 'zipCode', type: 'string', label: 'zipCode', mandatory: true},
  {
    id: "emergencyContact",
    type: "string",
    label: "emergencyContact",
    mandatory: false,
  },
  {
    id: "emergencyContactPhone",
    type: "string",
    label: "emergencyContactPhone",
    mandatory: false,
  },
  // {id: 'country', type: 'country', label: 'country', mandatory: true},
  {
    id: "car",
    type: "switchString",
    label: "driveInPermit",
    mandatory: false,
    settings: { subLabel: "carPlate", subId: "carPlate" },
  },
];

export const emailAsUsername = true;
export const supportedLanguages = [
  "de",
  "en",
  "fr",
  "bg",
  "cs",
  "el",
  "hr",
  "hu",
  "it",
  "lv",
  "pl",
  "pt",
  "ru",
  "ro",
  "es",
  "tr",
  "sr",
  "et",
  "nl",
  "ar",
];
export const defaultLanguage = "de";
export const registrationKeyMinimumLength = 4;

// Language overrides allow to override specific values for translation files, this is an example to change 'Zertifikat' to 'Teilnahmebescheinigung'
export const languageOverrides = {
  de: {
    courses: {
      certificate: "Teilnahmebescheinigung",
      certificates: "Teilnahmebescheinigungen",
      printCertificate: "Teilnahmebescheinigung drucken",
    },
    quizzes: {
      downloadCertificate:
        "Sie können jederzeit ihre Teilnahmebescheinigung unter Teilnahmebescheinigungen im Profil herunterladen und via E-Mail anfordern.",
    },
  },
};
