import countries from "i18n-iso-countries";
import { useI18n } from "vue-i18n";
import { i18n } from "@/main";

import ar from "i18n-iso-countries/langs/ar.json";
import bg from "i18n-iso-countries/langs/bg.json";
import bs from "i18n-iso-countries/langs/bs.json";
import cs from "i18n-iso-countries/langs/cs.json";
import de from "i18n-iso-countries/langs/de.json";
import el from "i18n-iso-countries/langs/el.json";
import en from "i18n-iso-countries/langs/en.json";
import es from "i18n-iso-countries/langs/es.json";
import et from "i18n-iso-countries/langs/et.json";
import fr from "i18n-iso-countries/langs/fr.json";
import hr from "i18n-iso-countries/langs/hr.json";
import hu from "i18n-iso-countries/langs/hu.json";
import it from "i18n-iso-countries/langs/it.json";
import lv from "i18n-iso-countries/langs/lv.json";
import nl from "i18n-iso-countries/langs/nl.json";
import pl from "i18n-iso-countries/langs/pl.json";
import pt from "i18n-iso-countries/langs/pt.json";
import ro from "i18n-iso-countries/langs/ro.json";
import ru from "i18n-iso-countries/langs/ru.json";
import sk from "i18n-iso-countries/langs/sk.json";
import sl from "i18n-iso-countries/langs/sl.json";
import tr from "i18n-iso-countries/langs/tr.json";
import { getUserLanguage } from "./userLanguage";

// In Germany anyone from EU, EWR and suisse is allowed to work without any restrictions
// https://www.arbeitsagentur.de/fuer-menschen-aus-dem-ausland/voraussetzungen-arbeiten-in-deutschland
export const europeanCountries = [
  "IS",
  "LI",
  "NO",
  "BE",
  "HR",
  "SE",
  "BG",
  "LV",
  "CH",
  "DK",
  "LT",
  "SI",
  "DE",
  "LU",
  "ES",
  "EE",
  "MT",
  "CZ",
  "FI",
  "NL",
  "HU",
  "FR",
  "AT",
  "CY",
  "GR",
  "PL",
  "IE",
  "PT",
  "IT",
  "RO",
  "SK",
] as const;

export function countryFromCode(code: string): string {
  countries.registerLocale(ar);
  countries.registerLocale(bg);
  countries.registerLocale(bs);
  countries.registerLocale(cs);
  countries.registerLocale(de);
  countries.registerLocale(el);
  countries.registerLocale(en);
  countries.registerLocale(es);
  countries.registerLocale(et);
  countries.registerLocale(fr);
  countries.registerLocale(hr);
  countries.registerLocale(hu);
  countries.registerLocale(it);
  countries.registerLocale(lv);
  countries.registerLocale(nl);
  countries.registerLocale(pl);
  countries.registerLocale(pt);
  countries.registerLocale(ro);
  countries.registerLocale(ru);
  countries.registerLocale(sk);
  countries.registerLocale(sl);
  countries.registerLocale(tr);

  if (code === "XA") {
    return i18n.global.t("stateless");
  }
  const lang = getUserLanguage();

  return countries.getName(lang, code) || "";
}

export function getAllCountries() {
  countries.registerLocale(ar);
  countries.registerLocale(bg);
  countries.registerLocale(bs);
  countries.registerLocale(cs);
  countries.registerLocale(de);
  countries.registerLocale(el);
  countries.registerLocale(en);
  countries.registerLocale(es);
  countries.registerLocale(et);
  countries.registerLocale(fr);
  countries.registerLocale(hr);
  countries.registerLocale(hu);
  countries.registerLocale(it);
  countries.registerLocale(lv);
  countries.registerLocale(nl);
  countries.registerLocale(pl);
  countries.registerLocale(pt);
  countries.registerLocale(ro);
  countries.registerLocale(ru);
  countries.registerLocale(sk);
  countries.registerLocale(sl);
  countries.registerLocale(tr);

  const lang = getUserLanguage();

  const c = countries.getNames(lang, { select: "official" });
  c["XA"] = i18n.global.t("stateless");
  return c;
}
